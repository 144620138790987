import baseColors from '@elegantstack/flow-ui-theme/src/theme/colors'

//Using tailwind preset colors
import colorPreset from '@elegantstack/flow-ui-theme/src/theme/color-preset'

const colors = {
  ...baseColors,
  alphaLighter: colorPreset.green[100],
  alphaLight: colorPreset.green[300],
  alpha: colorPreset.green[600],
  alphaDark: colorPreset.green[800],
  alphaDarker: colorPreset.green[900],
  text:'#fff',
  background:colorPreset.green[100],
  headerBg:colorPreset.teal[100]
}

export default colors