import React, { useContext } from 'react'
import { Container, Box, Flex } from 'theme-ui'
import pageContextProvider from '@helpers/pageContextProvider'
import Search from '@widgets/Search'
import { HeaderLogo } from './Header.Logo'
import { HeaderMenu } from './Header.Menu'
import { HeaderColorMode } from './Header.ColorMode'
import './header.scss'
const styles = {
  wrapper: {
    position: `relative`,
    bg: `headerBg`
  },
  container: {
    position: `relative`,
    zIndex: 10
  },
  logoContainer: {
    flexBasis: [`full`, null, `25%`]
  },
  menuContainer: {
    flexBasis: [`auto`, null, `60%`],
    minWidth: `auto`,
    order: [4, null, `unset`]
  }
}
export const Header = ({ children }) => {
  const context = useContext(pageContextProvider)

  const { services, mobileMenu, darkMode } = context.pageContext

  const algolia = services && services.algolia
  const [scroll, setScroll] = React.useState(false);
  React.useEffect(() => {
 window.addEventListener("scroll", () => {
   setScroll(window.scrollY > 50);
 });})
  return (
    <div className={`headerContainer ${scroll ? 'fixed':''}`}>
    <Box sx={styles.wrapper}>
      <Container variant='compact' sx={styles.container}>
        <Flex variant='layout.header'>
          <Box sx={styles.logoContainer}>
            <HeaderLogo />
          </Box>
          <Box sx={styles.searchContainer}>{algolia && <Search />}</Box>
          <Box sx={styles.menuContainer}>
            <HeaderMenu mobileMenu={mobileMenu} />
          </Box>
          <Box sx={styles.colorModeContainer}>
            {darkMode && <HeaderColorMode />}
          </Box>
        </Flex>
      </Container>
      {children}
    </Box></div>
  )
}
